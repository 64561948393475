body {
  background: #373737;
  font-family: monospace;
}

#title {
  margin: 0 auto;
  display: block;
}

#mainView {
  width: fit-content;
  max-width: 100%;
  min-width: 500px;
  background: #000;
  border-radius: 10px;
  margin: 20px auto;
  padding: 10px 0;
  display: block;
  box-shadow: 0 0 10px #000;
}

.scrollable {
  padding: 0 12px;
  overflow: auto;
}

.scrollable::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #000;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #373737;
}

#instruments {
  padding: 12px;
}

#list {
  clear: both;
  float: left;
}

a, a:link, a:visited {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: gray;
  text-decoration: underline;
}

/*# sourceMappingURL=index.c16fa6e2.css.map */
