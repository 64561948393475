body {
  background: #373737;
  font-family: monospace;
}

#title {
  display: block;
  margin: 0 auto;
}

#mainView {
  width: fit-content;
  max-width: 100%;
  margin: 20px auto;
  display: block;
  background: black;
  min-width: 500px;
  box-shadow: 0px 0px 10px #000;
  border-radius: 10px;
  padding: 10px 0;
}

.scrollable {
  overflow: auto;
  padding: 0 12px;
}

.scrollable::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #000;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #373737;
}

#instruments {
  padding: 12px;
}

#list {
  clear: both;
  float: left;
}

a,
a:link,
a:visited {
  color: white;
  text-decoration: none;
}

a:hover {
  color: gray;
  text-decoration: underline;
}
